exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-notes-callum-workflow-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/buildhome/repo/site/src/docs/Notes/callum-workflow.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-notes-callum-workflow-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-crm-migration-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/buildhome/repo/site/src/docs/Proposals/CRM-migration.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-crm-migration-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-hybrid-serverless-zapier-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/buildhome/repo/site/src/docs/Proposals/hybrid-serverless-zapier.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-hybrid-serverless-zapier-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-maintenance-plan-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/buildhome/repo/site/src/docs/Proposals/maintenance-plan.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-maintenance-plan-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-react-portal-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/buildhome/repo/site/src/docs/Proposals/react-portal.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-react-portal-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-serverless-api-layer-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/buildhome/repo/site/src/docs/Proposals/serverless-api-layer.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-serverless-api-layer-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-website-form-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/buildhome/repo/site/src/docs/Proposals/website-form.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-proposals-website-form-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-research-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js?__contentFilePath=/opt/buildhome/repo/site/src/docs/research.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js-content-file-path-src-docs-research-mdx" */),
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js-content-file-path-src-home-index-mdx": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/homepage-query.js?__contentFilePath=/opt/buildhome/repo/site/src/home/index.mdx" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-homepage-query-js-content-file-path-src-home-index-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

